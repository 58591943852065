import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-error404-page',
  templateUrl: './error404-page.component.html',
  styleUrls: ['./error404-page.component.scss']
})
export class Error404PageComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}

import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";

export interface Quote {
  author: string[];
  content: string[];
  tags: string[];
  date?: string;
}


const SEARCH_OPTIONS = {
  keys: ['author', 'content', 'tags'],
  threshold: 0.3,
  ignoreLocation: true
};

@Injectable({
  providedIn: 'root'
})
export class QuotesService {

  private quotesMap: Promise<Map<string, Quote[]>>;
  private Fuse = import('fuse.js').then(fuse => fuse.default);

  constructor(private http: HttpClient) {

    // Initialize quotesMap
    this.quotesMap = this.http.get<Record<string, Quote[]>>('/assets/quotes.json',
      {headers: new HttpHeaders().set('Accept', '*/*')}
    )
      .toPromise()
      .then(data => {
        return new Map(Object.entries(data));
      })
  }

  public async getQuotesForCategory(category: string) {
    return (await this.quotesMap).get('_' + category.toUpperCase());
  }

  public async getFilteredQuotesForCategory(category: string, {query}: { query: string }) {
    const categoryQuotes = await this.getQuotesForCategory(category);
    if (!categoryQuotes) {
      throw new Error('Category not found');
    }

    const fuse = new (await this.Fuse)(categoryQuotes, SEARCH_OPTIONS);

    return fuse.search<Quote>(query).map(r => r.item);
  }

}

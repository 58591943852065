import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {QuotesService} from "../quotes.service";

@Injectable({
  providedIn: 'root'
})
export class QuoteCategoryMustExistGuard implements CanActivate {

  constructor(private quotes: QuotesService, private router: Router) {
  }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {

    const category = route.paramMap.get('category');

    if (!category)
      return false;

    const quotes = await this.quotes.getQuotesForCategory(category);

    console.log(quotes)

    if (!quotes?.length) {
      return this.router.parseUrl('/404')
    } else {
      return true;
    }
  }

}

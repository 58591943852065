<app-navigation></app-navigation>

<main>
  <div class="quote-list">
    <!-- Suchfeld -->
    <form role="search">
      <input (ngModelChange)="searchQuery.next($event)" [(ngModel)]="searchQueryText"
             [class.info-visible]="mainState==='FILTERED_QUOTES'"
             aria-label="Suchen" name="search" placeholder="Suchbegriff..."
             type="search">
    </form>

    <!-- Info über Suchergebnisse -->
    <div *ngIf="mainState === 'FILTERED_QUOTES'" class="search-info">
      <p [ngPlural]="filteredQuotes.length">
        <ng-template ngPluralCase="=0">Keine Suchergebnisse gefunden</ng-template>
        <ng-template ngPluralCase="=1">1 Suchergebnis wird angezeigt</ng-template>
        <ng-template ngPluralCase="other">{{ filteredQuotes.length }} Suchergebnisse werden angezeigt</ng-template>
      </p>

      <button (click)="mainState = 'ALL_QUOTES'; searchQueryText = ''"
              aria-label="Alle Einträge anzeigen">✖
      </button>
    </div>

    <!-- Zitate -->
    <ng-container *ngIf="this.mainState !== 'LOADING'">
      <blockquote *ngFor="let quote of (this.mainState === 'FILTERED_QUOTES' ? filteredQuotes : allQuotes)">
        <p *ngFor="let line of quote.content" [innerHtml]="line"></p>
      </blockquote>
    </ng-container>
  </div>
</main>

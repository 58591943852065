import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {QuotesComponent} from "./quotes/quotes.component";
import {Error404PageComponent} from "./navigation/error404-page/error404-page.component";
import {QuoteCategoryMustExistGuard} from "./navigation/quote-category-must-exist.guard";

const routes: Routes = [
  {
    path: '404',
    component: Error404PageComponent
  },
  {
    path: ':category',
    pathMatch: 'full',
    component: QuotesComponent,
    canActivate: [QuoteCategoryMustExistGuard]
  },
  {
    path: '**',
    component: Error404PageComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import {Component, OnDestroy, OnInit} from '@angular/core';
import {Quote, QuotesService} from "../quotes.service";
import {ActivatedRoute} from "@angular/router";
import {SubSink} from "subsink";
import {Subject} from "rxjs";
import {debounceTime, map} from "rxjs/operators";

@Component({
  selector: 'app-quotes',
  templateUrl: './quotes.component.html',
  styleUrls: ['./quotes.component.scss']
})
export class QuotesComponent implements OnInit, OnDestroy {
  public mainState: 'LOADING' | '404' | 'ALL_QUOTES' | 'FILTERED_QUOTES' = 'LOADING';
  public allQuotes: Quote[] = [];
  public filteredQuotes: Quote[] = [];
  public searchQuery = new Subject<string>();
  searchQueryText = "";
  private subs = new SubSink();
  private category: string | null = null;
  private category$ = this.route.paramMap
    .pipe(map(paramsMap => paramsMap.get('category')));

  constructor(private quotesService: QuotesService, private route: ActivatedRoute) {
  }

  ngOnInit(): void {

    this.subs.sink = this.category$.subscribe(category => {
      this.category = category;
      this.searchQueryText = '';
      if (!this.category) {
        this.mainState = '404';
        return;
      }
      this.mainState = 'LOADING';

      this.quotesService.getQuotesForCategory(this.category).then(quotes => {
        if (quotes) {
          this.allQuotes = quotes;
          this.mainState = 'ALL_QUOTES';
        }
      })
    })


    // Search
    this.subs.sink = this.searchQuery.pipe(debounceTime(200)).subscribe(async query => {
      if (!this.category) {
        return;
      }

      if (query.trim() === '') {
        this.mainState = 'ALL_QUOTES';
        return;
      }

      this.quotesService.getFilteredQuotesForCategory(this.category, {query})
        .then(quotes => {
          console.log(quotes);
          this.filteredQuotes = quotes;
          this.mainState = 'FILTERED_QUOTES';
        });
    })
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

}
